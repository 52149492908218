import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const Partnerships = ({ images, partners }) => {
  return (
    <section className="container-fluid bg-white py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center text-center">
            <h5 className="text-uppercase font-weight-bold display-4">
              {partners.title}
            </h5>
          </div>
          <div className="col-12 col-md-5">
            <div className="pt-5 pt-md-0 row">
              {images.map(({node}, index) => (
                <div className="col-6 col-md-4 py-2 d-flex flex-column justify-content-center partners-images" key={index}>
                  <Img fluid={node.childImageSharp.fluid} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Partnerships.propTypes = {
  images: PropTypes.array.isRequired,
  partners: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
}

export default Partnerships