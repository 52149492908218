import React from "react"
import PropTypes from "prop-types"
import Sticky from 'react-sticky-el'

const Slogan = () => {
    return (
        <Sticky mode='bottom' className='sticky-slogan'>
            <div className="slogan text-only">
                <p className="title title-1 display-2 d-inline-block font-weight-bold px-3 text-uppercase text-nowrap">
                    Faut le croire
                </p>
                <div></div> {/* Separator */}
                <p className="title title-2 display-2 d-inline-block font-weight-bold px-3 text-uppercase text-nowrap">
                    Pour le voir
                </p>
            </div>
        </Sticky>
    )
}

const Content = ({ info, title }) => {
   return (
      <React.Fragment>
          <div className="slogan fixed">
              <p className="title title-1 display-2 d-inline-block font-weight-bold bg-primary px-3 text-uppercase text-nowrap">
                  Faut le croire
              </p>
              <div></div> {/* Separator */}
              <p className="title title-2 display-2 d-inline-block font-weight-bold bg-primary px-3 text-uppercase text-nowrap">
                  Pour le voir
              </p>
          </div>
          <section className="container-fluid bg-light main-description">
              <div className="row align-items-center">
                  <div className="col-12 col-md-6">
                      <Slogan />
                  </div>
                  <div className="col-12 col-md-5">
                      <div className='content'>
                        <h4 className="title text-uppercase">Des Autochtones qui brisent les préjugés?</h4>
                        <h4 className="title text-uppercase mt-0">Faut le croire pour le voir.</h4>
                        <p className="info font-weight-light pt-4">
                            {info}
                        </p>
                        <p className="info font-weight-light">
                          Plutôt que de croire les préjugés envers les Autochtones, croyons en eux.
                        </p>
                      </div>
                  </div>
              </div>
          </section>
      </React.Fragment>
    )
}

Content.propTypes = {
  info: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Content