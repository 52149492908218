import React from "react"
import { graphql } from "gatsby"
import Banner from "../components/global/banner"
import HomeContent from "../components/home/content"
import HomePartnerships from "../components/home/partnerships"
import HomePrejudicesList from "../components/home/prejudicesList"
import Layout from "../components/global/layout"
import SEO from "../components/seo/seo"
import Stories from "../components/global/stories"

export default ({ data }) => {
  const {
    header,
    partners,
    prejudices,
    video
  } = data.pagesJson
  const images = data.allFile.edges

  return (
    <Layout page='index'>
      <SEO title={`${header.titleFirstLine} ${header.titleSecondLine}`} />
      <Banner titleFirstLine={header.titleFirstLine} titleSecondLine={header.titleSecondLine} videoId={video}/>
      <HomeContent info={header.info} title={header.sub_title}/>
      <Stories/>
      <HomePrejudicesList content={prejudices}/>
      <HomePartnerships images={images} partners={partners}/>
    </Layout>
  )
}

export const query = graphql`
  query {
    pagesJson(id: { eq: "home" }) {
      header {
        info,
        sub_title,
        titleFirstLine,
        titleSecondLine
      },
      partners {
        title
      },
      prejudices {
        cta,
        sub_title,
        title
      },
      video
    },
    allFile(filter:{ relativePath: { regex: "/^images/home/" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`