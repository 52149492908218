import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Malarquee from 'react-malarquee'
import PropTypes from "prop-types"

const PrejudicesList = ({ content }) => {
  const { list } = getList().pagesJson
  const {
    cta,
    sub_title,
    title
  } = content

  return (
    <section className="container-fluid bg-white py-5 overflow-hidden">
      <div className="container text-center">

        <div className="row my-5">
          <div className="col-12">
            <h3 className="display-4 text-uppercase font-weight-bold">
              {title}
            </h3>
          </div>
          <div className="col-12 col-md-6 offset-md-3 pt-2">
            <p className="font-weight-light">
              {sub_title}
            </p>
          </div>
        </div>
      
        <div className="row my-5 py-5">
          <div className="col-12 col-md-10 offset-md-1 bg-primary py-3">
            <Malarquee rate={75} className="mx-n100">
              <Keywords list={list.slice(0, 4)} />
            </Malarquee>
            <Malarquee rate={80} className="mx-n100">
              <Keywords list={list.slice(5, 9)} />
            </Malarquee>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-12">
            <Link to={`/prejuges`} className="btn btn-dark p-3 text-white bg-dark btn-outline-dark">
              {cta}
            </Link>
          </div>
        </div>

      </div>
    </section>
  )
}

const Keywords = ({list}) => {
  return (
    <ul className="d-flex flex-nowrap list-unstyled text-dark text-uppercase m-0">
      {list.map(({keyword}, index) => (
        <li key={index} className="text-nowrap py-3 d-flex justify-content-between align-items-center">
          <h4 className="m-0">{keyword}</h4>
          <span className="separator"></span>
        </li>
      ))}
    </ul>
  )
}

const getList = () => {
  return useStaticQuery(
    graphql`
      query {
        pagesJson(id: { eq: "prejudice" }) {
          list {
            keyword
          }
        }
      }
    `
  )
}

PrejudicesList.propTypes = {
  content: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    sub_title: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default PrejudicesList